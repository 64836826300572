
div.dropdownComponent {
  padding-bottom: 1em;
  padding-top: 1em;
}

.appContainer {
  background-color: #000000;
  color: #000000;
  display: flex;
  flex-direction: column;
  background-size: cover;
}

.ratingText {
  text-align: center;
  color: white;
}

div.searchComponent {
  display: flex;
  justify-content: center;
  background-color: #000000;
  flex-direction: column;
  align-items: center;
}

.searchBoxComponent {
  align-items: center;
  width: 300px;
}

.bodyText {
  color: white;
  padding-top: 0.2em;
}

button.submitButton {
  margin-top: 1em;
  margin-bottom: 2em;
  color: black;
  background-color: lightblue;
  text-align: center;
  align-self: center;
}

.searchBoxErrorText {
  color: red;
  text-align: center;
  font-size: medium;
}

.head {
  background-color: #000000;
}

#root {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100vw;
}

.body {
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  align-items: center; 
  background-color: #000000;
  height: 800px;
}

div.primaryContainer {
  display: flex; 
  flex-direction: column;
  align-items: center;
  min-height: 800px;
}

.reviewTitle {
  text-decoration: solid;
  font-size: 24px;
  text-align: start;
  color: blue;
}

.reviewTile {
  text-align: start;
  padding-top: 2em;
  background-color: #000000;
}

.reviewBody {
  color: blue;
  font-style: italic;
}

.reviewSubtitle {
  text-align: start;
  color: blue;
}

.reviewDate {
  text-align: start;
  color: blue;
  padding-top: 3px;
}

.container { 
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  background-size: cover;
}
